/** Chart */
.div-mini-chart {
	
	svg {
		overflow: visible;
	}
	
	.ct-labels {
		display: none;
	}
	.controlers {
		line-height: 1.1;
		padding: .625rem 1.25rem;
		text-align: center;
		font-weight: 500;
		color: #00389F;
		
		#sms-controler-prev,
		#sms-controler-next {
			width: 40px;
			background-color: transparent;
			border: none;
			color: #00389F;
		}
		
		#sms-change-type {
			position: absolute;
			right: 20px;
			background-color: transparent;
			border: none;
			color: #00389F;
		}
	}
	
	#mini-chart {
		height: 182px;
		.ct-series.ct-series-a {
			.ct-area {
				fill: #54d4ce;
				fill-opacity: 1;
			}
			
			.ct-point,
			.ct-line,
			.ct-bar,
			.ct-slice-donut {
				stroke: #00389F;
				
				&.active {
					stroke: #de2626;
				}
			}
			
			.ct-line {
				stroke-width: 2px;
			}
			
		}
		
		.ct-label.ct-horizontal.ct-end {
			position: relative;
			top: -3.0rem;
			left: -1.75rem;
			width: 40px !important;
			text-align: center;
			color: #00389f;
			font-size: 14px;
		}
	}
}
.caroom-tooltip {
	position: absolute;
	display: inline-block;
	min-width: 5rem;
	padding: 0.25rem 0.625rem;
	background: #de2626;
	color: #FFF;
	text-align: center;
	pointer-events: none;
	z-index: 1000;
	border-radius: 2.5rem;
	white-space: nowrap;
	
	&:after {
		content: "";
		position: absolute;
		top: 100%;
		left: 50%;
		width: 0;
		height: 0;
		margin-left: -.375rem;
		border: .375rem solid transparent;
		border-top-color: #de2626;
	}
}
.div-donut {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	flex-wrap: wrap;
	
	.mini-donut {
		position: relative;
		.pie-datas-area {
			display: none;
			position: absolute;
			top: 32%;
			left: 28%;
			text-align: center;
			width: 45%;
			
			.value {
				
				span {
					display: block;
					font-size: 3.75rem;
				}
			}
			
			&.show {
				display: initial;
			}
		}
	}
	.ct-slice-donut {
		opacity: .4;
		-webkit-transition: all 250ms ease-out;
		-o-transition: all 250ms ease-out;
		transition: all 250ms ease-out;
		
		&:hover, &.active {
			opacity: 1;
			stroke-width: 40px !important;
		}
	}
	
	.ct-series-a .ct-slice-donut {
		stroke: #2ecc71 !important;
	}
	.ct-series-b .ct-slice-donut {
		stroke: #3498db !important;
	}
	.ct-series-c .ct-slice-donut {
		stroke: #9b59b6 !important;
	}
	.ct-series-d .ct-slice-donut {
		stroke: #f1c40f !important;
	}
	.ct-series-e .ct-slice-donut {
		stroke: #e67e22 !important;
	}
	
	#donut-change-type {
		background-color: transparent;
		border: none;
		color: #00389F;
		padding-left: 40px;
		margin-bottom: 20px;
	}
	
	.pie-overview-labels {
		
		li {
			display: block;
			padding: .625rem 0;
			vertical-align: middle;
			line-height: .875rem;
			font-weight: 600;
			cursor: pointer;
			
			&:before {
				position: relative;
				top: .125rem;
				content: '';
				display: inline-block;
				width: .875rem;
				height: .875rem;
				margin-right: .625rem;
				background-color: #f06448;
				-webkit-border-radius: .25rem;
				border-radius: .25rem;
				
			}
			
			&.ct-series-a:before {
				background-color:#2ecc71;
			}
			&.ct-series-b:before {
				background-color:#3498db;
			}
			&.ct-series-c:before {
				background-color:#9b59b6;
			}
			&.ct-series-d:before {
				background-color:#f1c40f;
			}
			&.ct-series-e:before {
				background-color:#e67e22;
			}
		}
	}
}